
//@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Manrope:wght@200..800&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500&family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');

// fonts
@font-face {
    font-family: 'NeverMind-Bold'; 
    src: url('./assets/fonts/NeverMind-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

@font-face {
    font-family: 'NeverMindHeadline-Bold';  /* Replace with your custom font name */
    src: url('./assets/fonts/NeverMindHeadline-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: 'NeverMind-Medium';  /* Replace with your custom font name */
    src: url('./assets/fonts/NeverMind-Medium.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'NeverMind-Regular';  /* Replace with your custom font name */
    src: url('./assets/fonts/NeverMind-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

// colors
$text-color: #EDEDED;
$black: #0B090E;
$gradient: linear-gradient(to right, #00B4D0,  #974198);

%gradient-text {
    background: $gradient;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    color: transparent;
    display: inline; /* Ensures the element does not span full width */
  }

%card {
    border-radius: 17px;
    background-color: $black;
    border: #EDEDED solid 2px;
}


.body {
    padding-left: 8vw; 
    padding-right: 8vw; 
    padding-top: 4vh;
    background-image: url("./img/background.webp");
    background-repeat: repeat;
    background-position: top left;
    background-size: contain;
    height: 100%;
    overflow: hidden;
    color: $text-color;
    font-family: "NeverMind-Regular", sans-serif;

        @media (max-width: 480px){
            background-image: url("./img/background_mobile.webp");
            background-repeat: repeat-y;
            background-position: top left;
            background-size: contain;
        }

    .App-header{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 10vh 80vh 10vh;
        height: 100vh; /* Example height */

        @media (max-width: 480px){
            grid-template-columns: 100vw;
            grid-template-rows: 10vh 40vh 40vh;
            justify-content: center;
        }
    }

    .logo-dws{
        margin-top: 4vh;
        width: 15vw;

        @media (max-width: 480px){
            margin-top: 2vh;
            margin-left: 2vh;
            width: 40vw;
        }
    }

    .main-info{
        grid-column: 1;
        grid-row: 2;
        align-self: center;

        @media (max-width: 480px){
            grid-row: 3;
            text-align: center;
        }
    }

    .animation{
        grid-column: 2;
        grid-row: 2;
        height: 100vh;
        //background-image: url("./img/blob.png");
        //background-repeat: no-repeat;
        //background-position: center;
        align-self: center;
        //overflow: hidden;

        .blob-static{
            z-index: -1;
        }

        @media (max-width: 480px){
            grid-column: 1;
            grid-row: 2;
            height: 50vh;
            overflow: hidden;
        }
    }

    .what-is-it{
        display: grid;
        margin-top: 5vh;
        margin-bottom: 5vh;
        grid-template-columns: 40% 60%;
        grid-template-rows: 100%;
        align-items: center;

        @media (max-width: 480px){
            grid-template-columns: 100%;
            grid-template-rows: 20% 80%;
            text-align: center;
        }

        #what-1{
            grid-column: 1;
        }

        #what-2{
            grid-column: 2;
            text-align: right;


            @media (max-width: 480px){
                grid-column: 1;
                text-align: center;
            }
        }
    }

    .ypo-tin-aigida{
        margin-top: 5vh;
        margin-bottom: 5vh;
        text-align: center;

        .blob-1-background{
            background-image: url("./img/blob.webp");
            position: absolute;
            height: 800px;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            width: 100%;
            overflow: hidden;
            z-index: 0;
            left: -40%;
            opacity: 0.2;

            @media (max-width: 480px){
                background-image: none;
            }
        }
    }

    .speakers {
        z-index: 2;
        position: relative;

        .speakers-title{
            text-align:right;
            margin-right: 2vw;
            margin-bottom: 1vh;

            @media (max-width: 480px){
                text-align:center;
            }
        }

        .cards {
            display: flex; 
            gap: 2vw; 
            justify-content: center; 
            flex-wrap: wrap; 
            padding: 1vw;
        }

        .speaker-card{
            display:grid;
            width: 18vw;
            height: 29vw;
            grid-template-rows: 66% 29% 5%;
            @extend %card;

            @media (max-width: 480px){
                width: 60vw;
                height: 90vw;
                margin: 1vh;
            }
    
            .speaker-img{
                grid-column:1;
                width: 18vw;
                height: 18vw;
                border-top-left-radius: 17px;
                border-top-right-radius: 17px;
                border-bottom-left-radius: 17px;
                border-bottom-right-radius: 17px;
                object-fit: cover;

                @media (max-width: 480px){
                    width: 60vw;
                    height: 60vw;
                }
            }
    
            .info {
                text-align: center;
                margin: 10px;
            }
    
            h6 {
                font-weight: bold;
                font-size:1.5vw;
                @extend %gradient-text;

                @media (max-width: 480px){
                    font-size:5vw;
                }
            }
    
            p{
                font-size:1vw;
                margin:0.5vh;

                @media (max-width: 480px){
                    font-size:3vw;
                }
            }
    
        }
    }



    .agenda{
        z-index: 2;
        position: relative;
        overflow: hidden;
        width: 100%;
        h4{
            font-size: 2vw;

            @media (max-width: 480px){
                font-size: 5vw;
            }
        }
        @media (max-width: 480px){
            text-align: center;
        }

        p {
            font-size: 1.2vw;

            @media (max-width: 480px){
                font-size: 4vw;
            }
        }
        h6
        {
            font-size: 0.8vw;
            font-style: italic;
            margin-top: -0.3em;
            margin-bottom: -0.1em;

            @media (max-width: 480px){
                font-size: 2.5vw;
            }
        }




        %agenda-card-large{
            display: grid;
            grid-template-columns: 40% 60%;
            grid-template-rows: 20% 35% 45%;
            text-align: center;
            height: 18vw;
            width: 38.5vw;
            @extend %card;

            @media (max-width: 480px){
                //margin-left:2vw;
                height: 50vw;
                width: 82vw;
            }
            
            .agenda-img{
                grid-column: 1;
                grid-row: 1/4;
                width:100%;
                height:100%;
                border-top-left-radius: 17px;
                border-top-right-radius: 0;
                border-bottom-left-radius: 17px;
                border-bottom-right-radius: 0;
                object-fit: cover;
            }

            .agenda-time{
                grid-column: 2;
                grid-row: 1;
                align-self:center;
            }

            .agenda-title{
                grid-column: 2;
                grid-row: 2;
                align-self: center;
                @extend %gradient-text;

            }

            .agenda-speakers{
                grid-column: 2;
                grid-row: 3;
                padding-left: 20px;
                padding-right: 20px;
                align-self: flex-start;

            }
        }

        %agenda-card-small{
            display: grid;
            grid-template-columns: 100%;
            grid-template-rows: 10% 1fr 1fr;
            row-gap: 1vh;
            height: 25vw;
            width: 23vw;
            text-align: center;
            padding: 1vw;
            @extend %card;

            @media (max-width: 480px){
                height: 70vw;
                width: 80vw;
            }

            .agenda-time{
                grid-column: 1;
                grid-row: 1;
                align-self: flex-start;
            }

            .agenda-title{
                grid-column: 1;
                grid-row: 2;
                align-self: center;
                @extend %gradient-text;
            }

            .agenda-speakers{
                grid-column: 1;
                grid-row: 3;
                align-self: center;
            }

        }

        .agenda-grid-1{
            margin-top: 2vw;
            display: grid;
            grid-template-columns: 40vw 40vw;
            column-gap: 1.5vw;
            grid-template-rows: 100%;
            height: auto;

            @media (max-width: 480px){
                grid-template-columns: 100%;
                grid-template-rows: 1fr 1fr;
                row-gap: 2vh;
                height: auto;
            }

            .opening{
                grid-column: 1;
                @extend %agenda-card-large;
                @media (max-width: 480px){
                    grid-row: 1;
                }
            }

            .keynote{
                grid-column: 2;
                @extend %agenda-card-large;
                @media (max-width: 480px){
                    grid-column: 1;
                    grid-row: 2;
                    margin-bottom: 1vh;
                }
            }
        }
        .agenda-grid-2{
            margin-top: 2vw;
            display: grid;
            grid-template-columns: 26vw 26vw 26vw;
            column-gap: 1.5vw;
            height: auto;
            grid-template-rows: 100%;

            @media (max-width: 480px){
                margin-top: 2vh;
                grid-template-columns: 100%;
                row-gap: 3vh;
                height: 100%;
                grid-template-rows: 1fr 1fr 1fr;
            }

            .topic-1{
                grid-column: 1;
                @extend %agenda-card-small;

                @media (max-width: 480px){
                    grid-row: 1;
                }
            }

            .topic-2{
                grid-column: 2;
                @extend %agenda-card-small;
                @media (max-width: 480px){
                    grid-column: 1;
                    grid-row: 2;
                }
            }

            .topic-3{
                grid-column: 3;
                @extend %agenda-card-small;
                @media (max-width: 480px){
                    grid-column: 1;
                    grid-row: 3;
                }
            }
        }
    }

    .why-to-participate{
        display:grid;
        grid-template-columns: 50% 50%;
        grid-template-rows: 1fr 1fr;
        margin-top:20vh;
        height:100vh;

        .blob-2-background{
            background-image: url("./img/blob1.webp");
            position: absolute;
            height: 900px;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: right;
            width: 100%;
            overflow: hidden;
            z-index: 1;  /* Adjust if necessary */
            right: 0px;
            opacity: 1;
            margin-top: -120vw;
    
            @media (max-width: 480px){
                background-image: none;
            }
        }

        .blob-3-background{
            background-image: url("./img/blob2.webp");
            position: absolute;
            height: 90vw;
            background-repeat: no-repeat;
            background-size: contain;
            width: 100%;
            overflow: hidden;
            z-index: 0;
            left: 0px;
            opacity: 1;
            margin-top: -30vw;

            @media (max-width: 480px){
                background-image: none;
            }
        }

        @media (max-width: 480px){
            grid-template-columns: 100%;
            grid-template-rows: 1fr 1fr;
            margin-top: -20vh;
        }

        h4{
            font-size: 3vw;

            @media (max-width: 480px){
                font-size: 8vw;
            }
        }

        .question{
            grid-row:1;
            grid-column: 1;
            text-align: left;
            align-self:center;
            margin-top: -6vw;
            

            @media (max-width: 480px){
                grid-row:1;
                grid-column: 1;
                text-align: center;
                align-self:end;
                margin-bottom: 33px;
                line-height: 1em;
            }
        }

        .reasons{
            grid-row:1;
            grid-column: 2;
            text-align: right;
            align-self:start;

            @media (max-width: 480px){
                grid-row:2;
                grid-column: 1;
                text-align: center;
                align-self:start;
            }

            .highlighted{
                font-family: 'NeverMind-Bold'; 
                font-weight: bold;
                font-size: 1.75vw;
                @extend %gradient-text;

                @media (max-width: 480px){
                    font-size: 5vw;
                }
            }
        }


    }

    .call-to-action{
        z-index:1;
        text-align: center;
        position: absolute;
        left: 50%;
        margin-top: -20vh;
        transform: translate(-50%, -50%);
        h3{
            font-size: 4.3vw;
        }
        @media (max-width: 480px){
            margin-top: 180px;
            margin-bottom: -80px;
            position:relative;
            h3{
                font-size: 12vw;
            }
        }
    }

    .sponsors{
        text-align: center;
        margin-bottom: 10vh;
    }

    .with-the-support{
        text-align: center;
        margin-bottom: 15vh;
    }

    .footer{
        
        
        height: 30vh;
        width: 100%;

        background: linear-gradient(to right, #00B4D0, #974198);

        position: absolute;
        left: 0;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        //border-radius: 30px;

        @media (max-width: 480px){
            height: 40vh;
            grid-template-columns: 100%;
            grid-template-rows: 1fr 1fr 1fr;
        }

        .footer-logo{
            grid-column: 1;
            place-self: center;

            @media (max-width: 480px){
                grid-row: 1;
            }

            .logo-dws-footer{
                width: 15vw;

                @media (max-width: 480px){
                    width: 40vw;
                }
            }
        }

        .footer-social{
            grid-column: 2;
            place-self: center;

            @media (max-width: 480px){
                grid-row: 2;
                grid-column: 1;
            }

            .social-icon {
                margin-right: 1vw;
                margin-left: 1vw;
                cursor:pointer;
                height: 3vw;
                @media (max-width: 480px){
                    height: 40px; }
            }
            .social-icon:hover {
                transform: scale(1.1);
            }
        }

        .footer-privacy{
            grid-column: 3;
            place-self: center;
            font-size: 1.3vw;

            @media (max-width: 480px){
                grid-row: 3;
                grid-column: 1;
                text-align:center;
                font-size: 1.1em;
            }

            a {
                color: $text-color;
            }
        }
    }


    

    .section {
        margin-top: 10vh;
    }

    .title {
        margin-bottom:2vh;
    }
    
    h1 {
        font-family: 'NeverMindHeadline-Bold'; 
        font-weight: bold;
        font-size: 5.5vw;
        margin-top: 2vh;
        margin-bottom:2vh;
        line-height: 1em;
        @extend %gradient-text;

        @media (max-width: 480px){
            font-size: 10vw;
        }
    }

    h2 {
        font-family: 'NeverMindHeadline-Bold'; 
        font-weight: bold;
        font-size: 4vw;
        line-height: 1em;
        @extend %gradient-text;

        @media (max-width: 480px){
            font-size: 8vw;
        }
    }

    h3 {
        font-family: 'NeverMindHeadline-Bold'; 
        font-weight: bold;
        font-size: 3vw;
        margin-bottom: 4vh;
        @extend %gradient-text;

        @media (max-width: 480px){
            font-size: 8vw;
        }
    }

    h4 {
        font-size: 2vw;
        margin-top: 1vh;
        margin-bottom:1vh;
        font-family: 'NeverMind-Bold'; 

        @media (max-width: 480px){
            font-size: 5vw;
        }

    }

    h6 {
        font-size: 1.5vw;
        font-weight:400;
        margin-top: 1vh;
        margin-bottom:1vh;

        @media (max-width: 480px){
            font-size: 5vw;
        }
    }

    p {
        font-size: 1.5vw;
        margin:0.5vh;

        @media (max-width: 480px){
            font-size: 5vw;
            margin:0.5vh;
        }
    }

    button {
        border-radius: 15px;
        border:none;
        color:black;
        background-color: $text-color;
        width: 12vw;
        height: 4vw;
        font-family: "NeverMind-Medium", serif;
        font-weight: bold;
        font-size: 1.2vw;
        margin-top: 2.5vh;
        margin-bottom:3vh;
        z-index:3;

        &:hover {
            background: $gradient;
            color:$text-color;
            cursor:pointer;
        }
    
        &:active {
            background: transparent;
            border: 2px solid $text-color;
        }
    
        &:focus {
            outline: none;  /* Remove default focus outline */
        }

        @media (max-width: 480px){
            width: 45vw;
            height: 15vw;
            font-size: 5vw;
        }
    }

    .live{
        button {
            border-radius: 15px;
            border-style: solid;
            border-width: 2px;
            border-color: red;
            color:red;
            background-color: transparent;
            width: 12vw;
            height: 4vw;
            font-family: "NeverMind-Medium", serif;
            font-weight: bold;
            font-size: 1.2vw;
            margin-top: 2.5vh;
            margin-bottom:3vh;
            z-index:3;
    
            &:hover {
                background: red;
                color:black;
                cursor:pointer;
            }
        
            &:active {
                background: transparent;
                border: 2px solid $text-color;
            }
        
            &:focus {
                outline: none;  /* Remove default focus outline */
            }
    
            @media (max-width: 480px){
                width: 45vw;
                height: 15vw;
                font-size: 5vw;
            }
        }

    }

    .logo{
        margin-left: 2vw;
        margin-right: 2vw;
        height: 12vh;
        align-self: center;

        @media (max-width: 480px){
            margin-top: 4vw;
            margin-bottom: 4vw;
            margin-left: 2vw;
            margin-right: 2vw;
            height: 120px;
            max-width: 80vw;
        }
    }
    .logo-un{
        margin-left: 2vw;
        margin-right: 2vw;
        height: 11vh;
        align-items: center;

        @media (max-width: 480px){
            margin-top: 4vw;
            margin-bottom: 4vw;
            margin-left: 2vw;
            margin-right: 2vw;
            height: 100px;
            max-width: 80vw;
        }
    }

    .logo_sponsors{
        margin-left: 1vw;
        margin-right: 1vw;
        height: 13vw;

        @media (max-width: 480px){
            margin-top: 0.3em;
            margin-bottom: 0.3em;
            margin-left: 0.5vw;
            margin-right: 0.5vw;
            height: 180px;
            max-width: 80vw;
        }
    }
    .logo_com_support{
        margin-left: 1vw;
        margin-right: 1vw;
        height: 9vw;

        @media (max-width: 480px){
            margin-top: 0em;
            margin-bottom: 0em;
            margin-left: 0.3em;
            margin-right: 0.3em;
            height: 88px;
            max-width: 80vw;
        }
    }

    .top{
        z-index: 2;
        position: relative;
    }



}